const COURSES_BASE_PATH = '/courses/public';

export const API_PATH = {
  COURSES: '/courses',
  MODULES: '/modules',
  SPEAKERS: '/speakers',
  PUBLIC: '/public',
};
const { COURSES, MODULES, SPEAKERS, PUBLIC } = API_PATH;

const GET_COURSES_DETAILS_PATH = (id: string) => {
  return `${COURSES}${PUBLIC}/${id}`;
};

const GET_COURSES_MODULES_PATH = (id: string) => {
  return `${COURSES}${PUBLIC}/${id}${MODULES}`;
};

const GET_COURSES_SPEAKERS_PATH = (id: string) => {
  return `${COURSES}${PUBLIC}/${id}${SPEAKERS}`;
};

const COURSES_API_PATHS = {
  GET_FEATURED_COURSES: `${COURSES_BASE_PATH}/featured-courses`,
};

export {
  COURSES_API_PATHS,
  COURSES_BASE_PATH,
  GET_COURSES_DETAILS_PATH,
  GET_COURSES_MODULES_PATH,
  GET_COURSES_SPEAKERS_PATH,
};
